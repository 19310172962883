import {LitElement, html, PropertyValues} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import styles from '../../styles/components/textfield.styles.scss'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons"
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';

@customElement('m-textfield')
export class TextfieldComponent extends LitElement {
    @property()
    label: string = "unbekannt"

    @property()
    placeholder: string = ""

    @property()
    validator: string = ""

    @property()
    isValid: boolean

    @property()
    type: string = "text"

    @property({type: Boolean})
    focused: boolean = false

    @property()
    value: string

    @property()
    errormessage: string = ""

    @property()
    optional: boolean = false

    error: string = ""

    @property()
    valuechange: (value:string) => void

    render() {
        return html`
            <style>${styles}</style>
            <input type="${this.type}" placeholder="${this.placeholder}" value="${this.value}" isvalid="${this.isValid}" @input="${this.handelInput}">
            <label class="${this.optional ? 'optional' : ""}">${this.label}</label>
            ${this.renderValidationIndicator()}`
    }

    renderValidationIndicator(){
        if(this.validator === "" || this.isValid === undefined) return

        if(this.isValid === true) return unsafeSVG(icon(faCircleCheck).html[0])
        else return unsafeSVG(icon(faCircleXmark).html[0])
    }

    handelInput(){
        this.value = this.shadowRoot.querySelector("input").value
        if(!this.validate()) return
        this.valuechange(this.value)
    }

    validate():boolean {
        if(this.optional) return true

        if(this.value == "" || this.value.replace(" ", "") == "") {
            this.error = "Bitte lassen sie keines der Felder leer"
            return this.isValid = false
        }

        let regex = new RegExp(this.validator);
        if(!regex.test(this.value)) {
            this.error = this.errormessage
            return this.isValid = false
        }

        return this.isValid = true
    }

    updated() {
        if(this.value != "") this.validate()

        if(!this.focused) return

        this.setFocus()
    }

    getError():string {
        return this.error
    }

    setFocus(){
        this.shadowRoot.querySelector("input").focus();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "m-textfield": TextfieldComponent;
    }
}