import {LitElement, html, PropertyValues} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import styles from '../../styles/components/dropdown.styles.scss'

export interface Option {
    name: string
    value: (string | number)
}

export enum DropdownType {
    DEFAULT="default",
    SIMPLE="simple"
}

@customElement('m-dropdown')
export class DropdownComponent extends LitElement {
    @property()
    value: string = ""

    @property()
    options: Option[] = []

    @property()
    disabled: boolean = false

    @property()
    valuechange: (value:string) => void

    @property()
    type: DropdownType = DropdownType.DEFAULT

    render() {
        return html`
            <style>${styles}</style>
            ${this.type == DropdownType.DEFAULT ? html`<label>${this.innerHTML}</label>` : ""}
            <select @change="${this.updateValue}" .disabled="${this.disabled}">
                ${ this.options && this.options[0]?.value == 0 ? "" : html`<option value="empty">auswählen</option>`}
                ${this.options?.map((option) =>
                        html`
                            <option .selected=${option?.value == this.value} value="${option?.value}">${option?.name}</option>
                        `
                )}
            </select>
        `
    }

    updateValue(e){
        let select = e.target as HTMLSelectElement
        select.blur()
        this.value = select.value
        this.valuechange(this.value)
    }

    reset(){
        this.value = "empty"
        this.valuechange(this.value)
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "m-dropdown": DropdownComponent;
    }
}
