import {LitElement, css, html} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import styles from '../../styles/components/progress.styles.scss'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import {unsafeSVG} from 'lit/directives/unsafe-svg.js';

@customElement('m-progress')
export class ProgressComponent extends LitElement {
    @property()
    steps: number = 1

    @property()
    progress?: number = 0

    @property()
    gotostep: (step:number) => void = () => {}

    @property()
    lockNavigation: boolean = false

    constructor(steps: number) {
        super()
        this.steps = steps
    }

    render() {
        let steps = []

        for (let i = 0; i < this.steps; i++) {
            const done = i < this.progress
            steps.push(html`
                <div class="step ${done ? 'done' : ''} ${i == this.progress ? 'current' : ''} ${this.lockNavigation ? 'locked' : ''}" @click="${()=>{this.handleClick(i)}}">
                    <span>${done ? unsafeSVG(icon(faCheck).html[0]) : i+1}</span>
                </div>`
            )
        }

        return html`
            <style>${styles}</style>
            <div class="m-progress">
                ${steps}
            </div>
        `
    }

    handleClick(step: number){
        if(step >= this.progress) return

        if(this.lockNavigation) return

        this.gotostep(step)
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "m-progress": ProgressComponent
    }
}