import {LitElement, html, PropertyValues} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import styles from '../../styles/components/numberinput.styles.scss'

@customElement('m-numberinput')
export class NumberinputComponent extends LitElement {
    @property()
    value: number = 0

    @property()
    price: number = 1

    @property()
    valuechange: (value:number) => void

    render() {
        return html`
            <style>${styles}</style>
            <div class="left">
                <label>${this.innerHTML}</label>
                <div class="number">
                    <div class="button" @click="${(e: Event) => {this.updateInputValue(-1, e)}}"><span>-</span></div>
                    <input .value="${this.value}" pattern="[0-9]*" inputmode="numeric" maxlength="2" @input="${this.setInputValue}" @click="${this.handleClick}">
                    <div class="button" @click="${(e: Event) => {this.updateInputValue(1, e)}}"><span>+</span></div>
                </div>
            </div>
            <p class="price">${this.value * this.price + "€"}</p>
        `
    }

    updateInputValue(value: number, e?: Event){
        let valueBefore = this.value
        this.value = Math.min(Math.max(0, this.value + value),6)

        if(valueBefore == this.value && e) {
            console.log("errer")
            let button = e.target as HTMLDivElement
            button.classList.add("error")
            setTimeout(() => {
                button.classList.remove("error")
                console.log("remove")
            },300)
        }

        this.valuechange(this.value)
    }

    setInputValue(e){
        let input = e.target as HTMLInputElement
        this.updateInputValue(Number(input.value.match(/\d+/)?.join("") || 0))
        input.value = String(this.value)
    }

    handleClick(e){
        if(this.value !== 0) return
        let input = e.target as HTMLInputElement
        input.select()
    }

    reset(){
        this.value = 0
        this.valuechange(this.value)
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "m-numberinput": NumberinputComponent;
    }
}
