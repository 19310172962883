import {LitElement, html, PropertyValues, TemplateResult} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import styles from '../../styles/components/reservation.styles.scss'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faXmark, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons"
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons"
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import {config, TableLocationCollection, TableLocation} from "../base"
import {Reservation,} from "../index"
import {TextfieldComponent} from "./textfield.component"
import Util from "../util"
import PopupEngine from "../popupEngine"
import {Option} from "./dropdown.component"
export interface AvailabiltyCounter {
    tickets: number
    tables: number
}

@customElement('m-reservation')
export class ReservationComponent extends LitElement {
    @property()
    private step:number = 0

    @property()
    private isOpen: boolean = false

    @property()
    ticketAvailability: number;

    eaCode:string = ""

    reservationSent:boolean = false

    @property()
    tables:TableLocationCollection = [
        {
            name: "Default", value: "dummy1", price: 20,
            sizes: [
                {name:"2er", value: 2, available: 20, booked: 15},
                {name:"4er", value: 4, available: 20, booked: 15},
                {name:"6er", value: 6, available: 20, booked: 15}
            ]
        }
    ]

    genders: Option[] = [
        {name: "Herr", value: "true"},
        {name: "Frau", value: "false"}
    ]

    @property()
    reservation: Reservation

    keypressed = {
        ctrl: false,
        esc: false,
        enter: false
    }

    constructor() {
        super()

        this.reservation = {
            customer: {
                firstname: "",
                lastname: "",
                mailaddress: "",
                phonenumber: "",
                schoolclass: "",
                isMale: undefined,
                street: "",
                streetNumber: "",
                zipCode: "",
                town: ""
            },
            tickets: {
                externalTicketCount: 0,
                studentTicketCount: 0
            },
            table: {
                location: "empty",
                size: 0
            }
        }

        document.addEventListener("keydown", e => {
            if(!this.isOpen) return

            switch (e.key) {
                case "Control":
                    this.keypressed.ctrl = true
                    break
                case "Escape":
                    this.keypressed.esc = true
                    break
                case "Enter":
                    this.keypressed.enter = true
                    break
            }

            if(this.keypressed.ctrl && this.keypressed.enter) this.next()
            if(this.keypressed.esc) this.close()
        })

        document.addEventListener("keyup", e => {
            if(!this.isOpen) return

            switch (e.key) {
                case "Control":
                    this.keypressed.ctrl = false
                    break
                case "Escape":
                    this.keypressed.esc = false
                    break
                case "Enter":
                    this.keypressed.enter = false
                    break
            }
        })
    }

    render() {
        let content: TemplateResult<1> = html``

        switch (this.step){
            case 0: //Tickets
                content = html`
                    <div class="top contact">
                        <h2>Kontakt</h2>
                        <m-dropdown type="simple"
                                .options="${this.genders}"
                                .valuechange="${(gender) => {this.reservation.customer.isMale = gender; this.requestUpdate()}}"
                                .value="${this.reservation.customer.isMale}"
                        ></m-dropdown>
                        <div class="row">
                            <m-textfield label="Vorname" placeholder="Max" 
                                         .valuechange="${(value)=>{this.reservation.customer.firstname = value}}"
                                         .value="${this.reservation.customer.firstname}"
                                         focused>
                            </m-textfield>
                            <m-textfield label="Nachname" placeholder="Mustermann"
                                         .valuechange="${(value)=>{this.reservation.customer.lastname = value}}"
                                         .value="${this.reservation.customer.lastname}">
                            </m-textfield>
                        </div>
                        
                        <div class="vertspace"></div>
                        
                        <m-textfield type="email" label="email" placeholder="m.mustermann@students.htl-leonding.ac.at" 
                                     validator="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
                                     errormessage="Bitte geben Sie eine valide E-Mail-Adresse ein (keine Sonderzeichen, muss ein @ haben)"
                                     .valuechange="${(value)=>{this.reservation.customer.mailaddress = value}}"
                                     .value="${this.reservation.customer.mailaddress}">
                        </m-textfield>
                        <m-textfield type="phone" label="Telefonnummer" placeholder="+43 99999999" 
                                     validator="^(\\+[2-9][2-9]|0)\\s?([1-9]\\d{0,3})?([2-9]\\d{2})\\s?(\\d{2}\\s?\\d{2,3}\\s?\\d{0,2})$"
                                     errormessage="Bitte geben Sie eine Telefonnummer im Format 0999 9999999 oder +99 9999999 ein"
                                     .valuechange="${(value)=>{this.reservation.customer.phonenumber = value}}"
                                     .value="${this.reservation.customer.phonenumber}">
                        </m-textfield>
                        
                        <div class="vertspace"></div>

                        <div class="row">
                            <m-textfield label="Straße" placeholder="Limesstraße"
                                         .valuechange="${(value)=>{this.reservation.customer.street = value}}"
                                         .value="${this.reservation.customer.street}"
                                         focused>
                            </m-textfield>
                            <m-textfield class="small" label="Nummer" placeholder="12-14a"
                                         .valuechange="${(value)=>{this.reservation.customer.streetNumber = value}}"
                                         .value="${this.reservation.customer.streetNumber}">
                            </m-textfield>
                        </div>

                        <div class="row">
                            <m-textfield label="PLZ" placeholder="4060"
                                         .valuechange="${(value)=>{this.reservation.customer.zipCode = value}}"
                                         .value="${this.reservation.customer.zipCode}"
                                         focused>
                            </m-textfield>
                            <m-textfield label="Stadt" placeholder="Leonding"
                                         .valuechange="${(value)=>{this.reservation.customer.town = value}}"
                                         .value="${this.reservation.customer.town}">
                            </m-textfield>
                        </div>
                        
                        <div class="vertspace"></div>

                        <m-textfield label="Klasse" placeholder="4BHITM"
                                     .valuechange="${(value)=>{this.reservation.customer.schoolclass = value}}"
                                     .value="${this.reservation.customer.schoolclass}"
                                     optional="true">
                        </m-textfield>
                        
                        <p class="errorMessage"></p>
                    </div>
                    <div class="bottom">
                        <m-button class="spacer" @click="${this.next}">Weiter</m-button>
                    </div>
                `
                break
            case 1: //Karten
                if(this.ticketAvailability <= 0 || config.override.tickets)
                    content = html`
                        <div class="top">
                            <p class="unavailable">Alle Karten sind bereits ausverkauft.</p>
                        </div>
                        <div class="bottom">
                            <m-button type="text" @click="${this.back}">Zurück</m-button>
                            <m-button @click="${this.next}">Weiter</m-button>
                        </div>`
                else
                    content = html`
                        <div class="top">
                            <div class="row">
                                <h2>Karten</h2>
                                <p class="availability spacer"><span>${this.ticketAvailability}</span>/${config.totalAvailabilityCount.tickets} verfügbar</p>
                            </div>
                            <m-numberinput price="${config.price.ticket.default}" 
                                           .valuechange="${(value:number)=>{this.reservation.tickets.externalTicketCount = value; this.requestUpdate()}}" 
                                           .value="${this.reservation.tickets.externalTicketCount}">Standardticket</m-numberinput>
                            <m-numberinput price="${config.price.ticket.student}" 
                                           .valuechange="${(value:number)=>{this.reservation.tickets.studentTicketCount = value; this.requestUpdate()}}" 
                                           .value="${this.reservation.tickets.studentTicketCount}">Schülerticket</m-numberinput>
                            <div class="row">
                                <m-button type="gray" @click="${this.clearTickets}">Tickets entfernen</m-button>
                                <div class="summe">
                                    <span class="line"></span>
                                    <p class="text">${this.calculateFullTicketPrice()}€</p>
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <m-button type="text" @click="${this.back}">Zurück</m-button>
                            <m-button @click="${this.next}">Weiter</m-button>
                        </div>
                    `
                break
            case 2: //Tisch
                if(config.override.tables)
                    content = html`
                            <div class="top">
                                <p class="unavailable">Alle Tische sind bereits ausverkauft.</p>
                            </div>
                            <div class="bottom">
                                <m-button type="text" @click="${this.back}">Zurück</m-button>
                                <m-button @click="${this.next}">Weiter</m-button>
                            </div>`
                else
                    content = html`
                        <div class="row top wrap">
                            <div class="left">
                                <div class="row">
                                    <h2>Tisch</h2>
                                    ${this.calculateTableSizeAvailability() == -1 ? "" : html`
                                        <p class="availability spacer">
                                            <span>${this.calculateTableSizeAvailability()}</span>
                                            /
                                            ${Util.getItemByValueFromJsonArray(Util.getItemByValueFromJsonArray(this.tables, this.reservation.table.location)?.sizes, this.reservation.table.size)?.available}
                                            verfügbar </p>`
                                    }
                                </div>
                                <m-dropdown
                                        .options="${this.tables}"
                                        .valuechange="${(location) => {
                                            this.reservation.table.location = location;
                                            this.requestUpdate()
                                        }}"
                                        .value="${this.reservation.table.location}"
                                >Saal
                                </m-dropdown>
                                <m-dropdown
                                        .options="${Util.getItemByValueFromJsonArray<TableLocation>(this.tables, this.reservation.table.location)?.sizes}"
                                        .valuechange="${(size) => {
                                            this.reservation.table.size = size;
                                            this.requestUpdate()
                                        }}"
                                        .disabled="${this.reservation.table.location == "empty"}"
                                        .value="${this.reservation.table.size}"
                                >Tisch
                                </m-dropdown>
                                <div class="row">
                                    <m-button type="gray" @click="${this.clearTables}">Tisch entfernen</m-button>
                                    <div class="summe">
                                        <span class="line"></span>
                                        <p class="text">${this.calculateTablePrice()}€</p>
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="floorplan-container" onclick="this.classList.toggle('zoomed')">
                                    <div class="zoom">${unsafeSVG(icon(faMagnifyingGlass).html[0])}</div>
                                    <img src="../../assets/location/${this.reservation.table.location == "empty" ? "default" : this.reservation.table.location}.png"
                                         alt="gebäudeplan">
                                    <p class="close-prompt">Erneut klicken, um das Bild zu verkleinern</p>
                                </div>
                                <a href="https://jimdo-storage.global.ssl.fastly.net/file/f6a4e262-1dae-4e8d-bffe-bb8ae9006b0f/Festaal_1_200.pdf"
                                   target="_blank">Gebäudeplan Palais</a>
                            </div>
                        </div>
                        <div class="bottom">
                            <p class="errorMessage"></p>
                            <m-button type="text" @click="${this.back}">Zurück</m-button>
                            <m-button @click="${this.next}">Weiter</m-button>
                        </div>
                    `
                break
            case 3: //Bestätigung
                let totalPrice = this.calculateFullTicketPrice() + this.calculateTablePrice()
                content = html`
                    <div class="top confirm">
                        ${totalPrice != 0 ? html`
                                    <h2>Bestätigung</h2>
                                    <div class="row heading">
                                        <h3>Kontakt</h3>
                                        <m-button type="gray" @click="${() => {
                                            this.goto(0)
                                        }}"
                                        ">bearbeiten</m-button>
                                    </div>
                                    <p>${this.reservation.customer.firstname} ${this.reservation.customer.lastname} •
                                        ${this.reservation.customer.mailaddress}</p>
                                ` :
                                html`
                                    <div class="empty">
                                        ${unsafeSVG(icon(faCircleXmark).html[0])}
                                        <p>Sie haben keine Tickets oder Tische ausgewählt, daher ist die Bestellung nicht
                                            möglich.</p>
                                    </div>
                                `}

                        ${this.calculateFullTicketPrice() == 0 ? "" : html`
                            <hr>
                            <div class="row heading">
                                <h3>Karten</h3>
                                <m-button type="gray" @click="${() => {
                                    this.goto(1)
                                }}">bearbeiten
                                </m-button>
                            </div>
                            <div class="row">
                                <p class="card">Standardkarten x${this.reservation.tickets.externalTicketCount} • <span>${this.reservation.tickets.externalTicketCount * config.price.ticket.default}
                                    €</span></p>
                                <p class="card">Schülerkarten x${this.reservation.tickets.studentTicketCount} •
                                    <span>${this.reservation.tickets.studentTicketCount * config.price.ticket.student}
                                        €</span></p>
                            </div>
                        `}

                        ${this.reservation.table.location == "empty" || this.reservation.table.size == 0 ? "" : html`
                            <hr>
                            <div class="row heading">
                                <h3>Tisch</h3>
                                <m-button type="gray" @click="${() => {
                                    this.goto(2)
                                }}">bearbeiten
                                </m-button>
                            </div>
                            <p class="card">
                                ${Util.getItemByValueFromJsonArray(this.tables, this.reservation.table.location)?.name}
                                •
                                ${Util.getItemByValueFromJsonArray(Util.getItemByValueFromJsonArray(this.tables, this.reservation.table.location)?.sizes, this.reservation.table.size)?.name}
                                •
                                <span>${this.calculateTablePrice()}€</span>
                            </p>
                        `}
                    </div>
                    <div class="bottom">
                        <m-button type="text" @click="${this.back}">Zurück</m-button>
                        ${
                                totalPrice == 0 ? html`
                                            <m-button type="text" @click="${() => {
                                                this.close()
                                            }}">Abbrechen
                                            </m-button>`
                                        :
                                        html`
                                            <div class="right">
                                                <p class="total spacer">Gesamtpreis: <span>${totalPrice}€</span></p>
                                                <m-button @click="${this.next}">Bestätigen</m-button>
                                            </div>
                                        `
                        }
                    </div>
                `
                break
            case 4: //Information
                content = html`
                    <div class="top information">
                        <h2>Information</h2>
                        <p>
                            Hiermit werden Karten/Tische für den Maturaball für Sie <span>reserviert</span>, das bedeutet:
                        <ul>
                            <li>Sie müssen Ihre Karten/Tische vor der Abholung bezahlen.</li>
                            <li>Informationen zur Abholung erhalten Sie nach Zahlungseingang.</li>
                            <li>Für jede Schülerkarte muss ein Schülerausweis vorgezeigt werden.</li>
                            <li>Mit Ihrer Reservierung verpflichten Sie sich zum Kauf der Karten und Tische.</li>
                        </ul>
                        </p>
                    </div>
                    <div class="bottom">
                        <div class="confirm spacer">
                            <label for="confirm">Ich habe die Information gelesen.</label>
                            <input name="confirm" type="checkbox" id="confirmBox">
                        </div>
                        <m-button @click="${this.next}">Kostenpflichtig reservieren</m-button>
                    </div>
                `
                break
            case 5: //Beendet
                content = html`
                    <div class="done">
                        <h3>Ihre Bestellung wird übermittelt ...</h3>
                        <p>Dies kann einige Sekunden dauern.</p>
                    </div>
                    <div class="bottom centerd">
                        <m-button @click="${()=>{this.close(false)}}">Fertig</m-button>
                    </div>
                `
                break
        }

        return html`
            <style>${styles}</style>
            <div class="content">
                <m-progress steps="5" progress="${this.step}" .gotostep=${(step) => {this.goto(step)}}></m-progress>
                <div class="close" @click="${() => {this.close()}}">${unsafeSVG(icon(faXmark).html[0])}</div>
                ${content}
            </div>
        `
    }

    //region navigation
    next(){
        console.log(this.reservation)

        //override input verification for testing purposes
        if(config.disableInputValidation){
            this.step++
            if(this.step == 5)
                window.requestAnimationFrame(()=>{this.shadowRoot.querySelector(".done").innerHTML = `<h3>disableInputValidation is true no reservation will be created</h3>`;})
            return
        }

        switch (this.step) {
            case 0: //kontakt
                if(!this.reservation.customer.isMale){
                    this.showError("Bitte wählen Sie eine Anrede")
                    return
                }

                let textfields = Array.from(this.shadowRoot.querySelectorAll('m-textfield'))
                //get all text fields that have a validator and check if any of them have invalid content
                for (let i = 0; i < textfields.length; i++) {
                    if(!textfields[i].validate()){
                        textfields[i].setFocus()
                        this.showError(textfields[i].getError())
                        return
                    }
                }
                break
            case 2: //tisch
                //if one table property is set and the other is unset dont proceed
                if((this.reservation.table.location != "empty" && this.reservation.table.size == 0) ||
                    (this.reservation.table.location == "empty" && this.reservation.table.size != 0)){
                    this.showError("Bitte wählen Sie Saal und Tischgröße, oder keines von beiden.")
                    return
                }
                break
            case 4: //Info
                let checkBox:HTMLInputElement = this.shadowRoot.querySelector('#confirmBox')
                if(!checkBox.checked){
                    this.shadowRoot.querySelector('.confirm').classList.add("error")
                    return
                }
        }


        if(this.step > 5) return

        if(this.step == 4 && this.reservationSent == false){ //confirmed
            this.reservationSent = true

            this.shadowRoot.querySelector("m-progress").lockNavigation = true

            fetch(config.api_url + "/reservation/book/" + this.eaCode, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(this.reservation),
            })
                .then(result => {
                    if(result.status == 200){
                        this.shadowRoot.querySelector(".done").innerHTML = `
                            ${icon(faCircleCheck).html[0]}
                            <h3>Wichtig!</h3>
                            <p> Wir haben an Ihre E-Mail: ${this.reservation.customer.mailaddress} einen <span>Aktivierungslink</span> versendet.
                                Bitte klicken Sie diesen, um Ihre Daten zu verifizieren und die <span>Reservierung abzuschließen.</span>
                                Ihre Karten 
                            </p>`
                    }
                    else {
                        this.shadowRoot.querySelector(".done").innerHTML = `
                            <h3>Ihre Reservierung ist fehlgeschlagen. Bitte versuchen Sie es später erneut.</h3>`
                    }
                    return result.text()
                })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.error(error)
                })
        }

        this.step++
    }
    back(){
        if(this.step <= 0) return
        this.step--
    }
    goto(step:number){
        this.step = step
    }

    open(eaCode = ""){
        this.reservation = {
            customer: {
                firstname: "",
                lastname: "",
                mailaddress: "",
                phonenumber: "",
                schoolclass: "",
                isMale: undefined,
                street: '',
                streetNumber: '',
                zipCode: '',
                town: ''
            },
            tickets: {
                externalTicketCount: 0,
                studentTicketCount: 0
            },
            table: {
                location: "empty",
                size: 0
            }
        }

        if(this.isOpen === true) return
        this.isOpen = true

        this.style.display = "grid"

        this.step = 0

        this.eaCode = eaCode

        this.reservationSent = false
    }

    close(showConfirmation:boolean = true){
        if(showConfirmation == true){
            PopupEngine.createModal({
                heading: "Reservierung abbrechen?",
                text: "Wenn Sie die Reservierung abbrechen werden eingegeben Daten nicht gespeichert. " +
                    "Es werden keine Artikel o.Ä. reserviert und es fallen keine Kosten an.",
                buttons: [
                    {
                        text: "okay",
                        action: () => {
                            this.isOpen = false
                            this.style.display = "none"
                        }
                    },
                    {
                        text: "reservierung fortsetzen"
                    },
                ]
            })
        }
        else{
            this.isOpen = false
            this.style.display = "none"
        }
    }

    errorTimeout: NodeJS.Timeout
    showError(text: string){
        console.log("showing")
        let errorElement:HTMLParagraphElement = this.shadowRoot.querySelector('.errorMessage')
        errorElement.innerText = text
        errorElement.animate([
            {marginBottom: "-1rem", opacity: 0},
            {marginBottom: "0", opacity: 1}
        ],
        {
            duration: 250,
            iterations: 1
        })
        clearTimeout(this.errorTimeout)
        this.errorTimeout = setTimeout(() => {
            console.log("timoutclear")
            this.clearError()
        },5000)
    }
    clearError(){
        console.log("clearing")
        let errorElement:HTMLParagraphElement = this.shadowRoot.querySelector('.errorMessage')
        if(!errorElement) return

        clearTimeout(this.errorTimeout)
        errorElement.innerHTML = ""
    }
    //endregion

    //region data handeling

    calculateFullTicketPrice(){
        return this.reservation.tickets.studentTicketCount * config.price.ticket.student +
               this.reservation.tickets.externalTicketCount * config.price.ticket.default
    }

    calculateTablePrice(){
        return Util.getItemByValueFromJsonArray(this.tables, this.reservation.table.location)?.price * this.reservation.table.size || 0
    }

    clearTickets(){
        this.shadowRoot.querySelectorAll('m-numberinput').forEach(input => {input.reset()})
    }

    clearTables(){
        this.shadowRoot.querySelectorAll('m-dropdown').forEach(input => {input.reset()})
    }

    calculateTableSizeAvailability(){
        let location = Util.getItemByValueFromJsonArray(this.tables, this.reservation.table.location)
        let size = Util.getItemByValueFromJsonArray(location?.sizes, this.reservation.table.size)

        if(!size) return -1

        return size.available - size.booked
    }

    //endregion
}

declare global {
    interface HTMLElementTagNameMap {
        "m-reservation": ReservationComponent;
    }
}
