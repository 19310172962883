import {LitElement, html, PropertyValues} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import styles from '../../styles/components/button.styles.scss'

enum ButtonType {DEFAULT="default", BIG="big", TEXT="text"}

@customElement('m-button')
export class ButtonComponent extends LitElement {
    @property()
    type:ButtonType = ButtonType.DEFAULT

    @property()
    clickAction = () => {}

    render() {
        return html`
            <style>${styles}</style>
            <button class="m-button" type="${this.type}" @click="${(e) => {this.playClickEffect(e); this.clickAction(); this.blur()}}">
                <span class="text">${this.innerHTML}</span>
            </button>`
    }

    playClickEffect(e:MouseEvent){
        this.classList.add("clicked")
        setTimeout(() => {
            this.classList.remove("clicked")
        },500)
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "m-button": ButtonComponent;
    }
}
